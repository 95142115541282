@tailwind base;
@tailwind components;
@tailwind utilities;

.react-datepicker {
  border: 1px solid #ECECEC !important;
  border-radius: 16px !important;
  padding: 12px !important
}

.react-datepicker__header {
  background-color: white !important;
  border: none !important;
  padding: 0px !important;
}
.react-datepicker__month-container {
  padding: 16px;
}

.react-datepicker__day-name {
  color: #CACACA !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: 70px !important;
  padding: 16px 4px !important;
  line-height: 1rem !important;
}
.react-datepicker select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: "";
}
.react-datepicker__day {
  position: relative;
  width: 70px !important;
  height: 70px !important;
  padding: 30px 8px 12px !important;
  line-height: 1rem !important;
  background-color: #F5F5F5 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #131A29 !important;
}
.react-datepicker__day:hover {
  background-color: #D4E9E2 !important;
  outline: 2px solid #006241 !important;
}

.react-datepicker__day--highlighted::after {
  position: absolute;
  content: "•";
  margin:0 10px;
  color: #006241;
  bottom: 5px;
  left: 0px;
  right: 0px;
}

.react-datepicker__day--outside-month {
  background-color: transparent !important; 
}

.react-datepicker__day--excluded {
  color: red !important;
}

.react-datepicker__day--excluded:hover {
  outline: unset !important;
}

.react-datepicker__day--selected {
  /* background-color:  !important; */
}
.react-datepicker__day--keyboard-selected {
  background-color: #006241 !important;
  color: white !important;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
  left: 0;
}

.react-datepicker__triangle {
  display: none !important;
}
.react-datepicker__input-container {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse !important;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #d2d5da;
  background: #fff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
}
.react-datepicker__view-calendar-icon input {
  padding: 0 !important;
}
.react-datepicker-popper {
  z-index: 10 !important;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__input-container input {
  width: 100% !important;
  appearance: none !important;
  outline: none !important;
  border: none !important;
}
.react-datepicker__input-container:focus input {
  outline: none !important;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: unset !important;
  padding: 0 !important;
}
.react-datepicker__calendar-icon {
  width: auto !important;
  height: auto !important;
}

.concept-banner {
  display: block;
}

.concept-mobile-banner {
  display: none;
}


@media (max-width: 768px) {
  .concept-banner {
    display: none;
  }
  .concept-mobile-banner {
    display: block;
  }
  .react-datepicker {
    border: none !important;
    padding: 12px !important
  }
  .react-datepicker__day-name {
    width: 50px !important;
    padding: 16px 4px !important;
  }
  .react-datepicker__day {
    width: 50px !important;
    height: 50px !important;
    padding: 16px 4px !important;
    font-size: 12px !important;
  }
}